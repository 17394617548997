import { useRouter } from 'next/navigation';
import { useRequestContext } from '@/lib/context';
import { useProgress } from '@/components/MainLayout/hooks/useProgress';
import { startTransition } from 'react';
import { urls } from '@/constants';

/**
 * TO BE REMOVED AFTER FULL SSR RELEASE
 */

export const useVeniaRouter = () => {
    const router = useRouter();
    const { isVenia } = useRequestContext();
    const { startLoading } = useProgress();

    const overwrittenPush = (path: string, options?: any) => {
        startTransition(() => {
            startLoading?.();

            if (isVenia && (!path.includes(urls.checkout) || path.includes(urls.cart))) {
                globalThis.location.href = path;
            } else {
                router.push(path, options);
            }
        });
    };

    return {
        ...router,
        push: overwrittenPush,
    };
};
