import { useVeniaRouter } from '@/utils/veniaRouter';

export const useHistory = () => {
    const router = useVeniaRouter();

    return {
        push: (path: string) => {
            router.push(path);
        },
    };
};
